import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
    AppConfig,
    JourneyConfig,
} from '@r6digital/rapidstor-types/react/entities/Journey';
import { useCookies } from 'react-cookie';

export const BaseConfig: AppConfig = {
    pages: {
        storageType: true,
        unitListing: true,
        bookUnit: 'standard',
    },
    journey: {
        locationCode: 'TEST',
        corpCode: 'CRUK',
        apiToken: '0zbo0yM8TFiiM0dmowS8f46h2M4WmClK',
        corpToken:
            'adfe4749288ea6045aafabadc8aab35a803ef666844261ac4149a6f7fde9e2e45f116e4aff94d16aedf2c37aed410331',
        themeColor: '#000',
    },
};

export interface CrukLocation {
    code: string;
    name: string;
    locationCode: string;
    unitTypeNames?: string;
    unitTypeRegex?: string;
}

const CRUK_CORP_TOKEN =
    'adfe4749288ea6045aafabadc8aab35a803ef666844261ac4149a6f7fde9e2e45f116e4aff94d16aedf2c37aed410331';
const CRUK_API_TOKEN = '0zbo0yM8TFiiM0dmowS8f46h2M4WmClK';
const CRUK_THEME_COLOR = '#202057';

export const CRUK_LOCATIONS: CrukLocation[] = [
    { code: 'BW', name: 'Bridgwater', locationCode: 'L001' },
    { code: 'TA', name: 'Taunton', locationCode: 'L005' },
    { code: 'B1', name: 'Bristol West', locationCode: 'L001' },
    { code: 'PL', name: 'Plymouth North', locationCode: 'L002' },
    { code: 'B2', name: 'Bristol Central', locationCode: 'L001' },
    { code: 'CH', name: 'Chard', locationCode: 'L003' },
    { code: 'GL', name: 'Gloucester', locationCode: 'L005' },
    { code: 'RR', name: 'Redruth', locationCode: 'L002' },
    { code: 'SW', name: 'Swindon', locationCode: 'L003' },
    { code: 'P2', name: 'Plymouth Central', locationCode: 'L002' },
    { code: 'WM', name: 'Weston Super Mare', locationCode: 'L004' },
    { code: 'YE', name: 'Yeovil', locationCode: 'L004' },
    { code: 'BN', name: 'Barnstaple', locationCode: 'L001' },
    { code: 'FH', name: 'Fareham', locationCode: 'L001' },
    { code: 'KM', name: 'Kidderminster', locationCode: 'L005' },
    { code: 'WC', name: 'Worcester', locationCode: 'L004' },
    { code: 'MK', name: 'Milton Keynes', locationCode: 'L005' },
    { code: 'WG', name: 'Wigan', locationCode: 'L004' },
    { code: 'LP', name: 'Liverpool', locationCode: 'L002' },
    { code: 'WR', name: 'Warrington', locationCode: 'L004' },
    { code: 'SD', name: 'Swadlincote', locationCode: 'L003' },
    { code: 'BT', name: 'Burton Upon Trent', locationCode: 'L001' },
    { code: 'LD', name: 'Leeds', locationCode: 'L002' },
    { code: 'ST', name: 'Stoke On Trent', locationCode: 'L003' },
    { code: 'TF', name: 'Telford', locationCode: 'L005' },
    { code: 'RG', name: 'Rugby', locationCode: 'L003' },
    { code: 'SF', name: 'Sheffield', locationCode: 'L003' },
    { code: 'YT', name: 'Yate', locationCode: 'L004' },
];

export const Locations: Record<string, JourneyConfig> = CRUK_LOCATIONS.reduce(
    (acc, loc) => {
        acc[loc.code] = {
            locationCode: loc.locationCode,
            corpCode: 'CRUK',
            apiToken: CRUK_API_TOKEN,
            corpToken: CRUK_CORP_TOKEN,
            themeColor: CRUK_THEME_COLOR,
            unitTypeNames: loc.unitTypeNames,
            unitTypeRegex: loc.code,
            loqate: {
                apiKey: 'RE21-MX62-RJ68-JM76',
                accountCode: 'UKSTO11111',
                host: 'UKSTO11111.pcapredict.com',
            },
        };
        return acc;
    },
    {} as Record<string, JourneyConfig>
);

export default function View() {
    const [searchParams] = useSearchParams();
    const [cookies] = useCookies();

    const locationCode = searchParams.get('locationCode');

    useEffect(() => {
        if (!locationCode) {
            console.error(`No config found for locationCode: ${locationCode}`);
            return;
        }

        const location = Locations[locationCode];

        if (!location) {
            console.error(`No config found for locationCode: ${locationCode}`);
            return;
        }

        const initApp = async () => {
            const contents = await fetch(`https://rapidstor-v2-frontend.pages.dev/`);
            const result = await contents.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(result, `text/html`);
            const script = doc.querySelector(`head script`);
            const st = document.createElement(`script`);
            const p = document.getElementById(`rapidstor-v2-frontend`);
            st.src =
                `https://rapidstor-v2-frontend.pages.dev` + script?.getAttribute(`src`);
            st.setAttribute(`type`, `module`);
            st.setAttribute(`crossorigin`, 'true');
            p?.appendChild(st);
            const container = document.getElementById(`rapidstor-v2-frontend`);
            container?.setAttribute(
                'data-appConfig',
                JSON.stringify({
                    ...BaseConfig,
                    journey: {
                        ...location,
                        agentDetails: {
                            agentName: cookies.agentDetails
                                ? cookies.agentDetails.agentName
                                : undefined,
                            agentComment: cookies.agentDetails
                                ? cookies.agentDetails.comments
                                : undefined,
                        },
                    },
                })
            );
        };

        initApp();
    }, [cookies.agentDetails, locationCode]);

    return (
        <div>
            <div
                id='rapidstor-v2-frontend'
                className='rapidstor-v2-frontend'
                style={{
                    minHeight: '100vh',
                }}
            ></div>
        </div>
    );
}
